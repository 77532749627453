html {
  box-sizing: border-box;
  font-family: "Helvetica Neue", Arial, sans-serif;
  text-align: justify;
  letter-spacing: -0.5px;
}


a{text-decoration: none; color: #AFFE00;}
#Smooth_Content .smsLink{cursor: pointer;}
a:hover{cursor: pointer;}

@media screen and (max-width: 1200px) and (orientation: portrait) {


body div#st-1.st-right{
  bottom: -1px;
  top:auto;
}

html h1{
  font-size: 12vw;
}
html h2{
  font-size: 6vw;
  line-height: 1.2;
}
html h3{
  font-size: 3.5vw;
}
html h4{
  font-size: 2.5vw;
}
html h5{
  font-size: 3vh;
}
html p{
  font-size: 4vw;
}

div.popAll{
 width: 80vw;
}

div.popBG{
  background-image: none;
}

div #icon canvas{
  position: absolute;
  left: 50%;
  top: 50%;
}

  h1.s2{
    position:inherit;
    text-align: center;
    left:auto;
  }  

  h1.s3{
    position:inherit;
    text-align: center;
    right:auto;
  }  

  nav.stl{
    height: 10vh;
    margin-top:1vh;
  }

  main.first10 {
    justify-content: center;
  }

  main.first10 img{
    width:50%;
    margin-top: 20vh;
  }

  img.star1{
    width: 10%;
  }
  img.star2{
    width: 6%;
  }
  img.star3{
    width: 12%;
  }
  img.star4{
    width: 8%;
  }
  img.star5{
    width: 7%;
  }

  main.firstTime img{
    width: 90%;
  }

  main.two h3{
    margin-top: 10vh;
    font-size: 6vw;
    text-align: left;
  }

  img.twoIcon{
    width: 6vw;
  }

  main.twoStep{
    flex-direction: column;
    width: 90%;
    margin: 10vh 0 10vh 0;
  }

  div.twoStep01{
    width: auto;
    height: 30vh;
    padding: 6%;
    margin: 0 0 6vh 0;
  }

  div.twoStep01-2{
    margin: -2px 0px -2px 0px;
  }

  div.twoStep02{
    width: auto;
    height: 30vh;
    padding: 6%;
    margin: 0;
  }

  div.twoStep03{
    flex-direction: column;
    width: auto;
  }

  div.twoStep03-1{
    width:auto;
    padding: 6%;
  }

  div.twoStep03-2{
    width: auto;
    padding: 10%;
  }

  div.sms1 span img {
    width: 6vw;
  }

  div.bestTXT{
    padding: 6%;
    margin: 10vh 0 6vh 0;
  }

  div.bestTXT h3{
    width: auto;
    font-size: 3vh;
  }

  main.awards{
    flex-direction: column;
  }

  main.awardsL{
    width: auto;
    height: 30vh;
  }

  div.awardsLTitle{
    width: 50vw;
  }

  div.awardsL-Event{
    margin-bottom: 0;
  }

  main.awardsR{
    width: auto;
  }

  main.awardsR-three-title{
    height: 20vh;
  }
  img.iconCreative, img.iconCreative2, img.iconCreative3{
    width: 10vh;
  }

  main.awardsR-three-title2{
    height: 20vh;
  }

  img.iconCreative2{
    width: 10vh;
  }

  main.awardsR-three-title3{
    height: 20vh;
  }

  main.awardsL{
    border:0px;
  }

  footer.foot img{
    width:100%;
  }
  div.footStar{
    width: 100vw;
  }
  footer.foot h2{
    margin: 10vh 0 2vh 0;
  }
}

/* 定义字体 */  
@font-face {
  font-family: "Orbitron";
  src: url('../img/Orbitron.woff') format('woff');
}
@font-face {
  font-family: "LCD";
  src: url('../img/LCD.woff') format('woff');
}
@font-face {
  font-family: "IBMPlexMono-Regular";
  src: url('../img/IBMPlexMono-Regular.woff') format('woff');
}

h1{font-family: "Orbitron";
  font-size: 5vw;
  margin: 0;
  line-height: 1.2;
  text-transform: uppercase;}

h2{font-size: 2.5vw;
  font-family: "Orbitron";
  text-transform: uppercase;
  color: hsl(79, 100%, 50%);
  box-sizing: border-box;
  margin: 0;
  line-height: 1.5;
  text-align: center;}  
h3{font-size: 1.5vw;
  font-family: "Orbitron";
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  line-height: 1.4;
  color: #AFFE00;}
h4{font-size:0.8vw;
  font-family: "Orbitron";
  text-transform: uppercase;
  margin: 0;
  color: #AFFE00;margin: 0;}
h5{font-size: 4vh;
  font-family: "LCD";
  margin: 0;
  color: #AFFE00;}
p{font-size: 1.2vw;
  font-family: "IBMPlexMono-Regular";
  color: #AFFE00;
  margin: 0;
  letter-spacing: 1px;
  font-weight:400;}

body{
  cursor:none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  background-color: #0A0C25;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.cursor .close{
  position: fixed;
  left: 50%;
  border-radius:50% ;
  pointer-events: none;
  transition: transform .1s;
  z-index: 1000000000;
}

.cursor{
  position: fixed;
  z-index: 1000000000;
  pointer-events: none;
  width: 5rem;
  height: auto;
  transform:rotate(30deg);
  transform-origin: 100% 100%;
}
.huoxingzi{
  opacity: 0;
}
.close{
  position: fixed;
  pointer-events: none;
  z-index: 1000000000;
  width: 0rem;

}

.pop{
  backdrop-filter: blur(10px); /* Chrome */
  -webkit-backdrop-filter: blur(10px); /* Safari */
  background-color: #121336f3;
  width: 100vw;
  height: 100vh;
  z-index: 1100;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: none;
}
.popAll{
  width: 40vw;
  height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  scale: 0;
}
.popBG{
  padding: 6%;
  background-image: url(../img/popBG.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  scale: 0;
}

.pop p{
  text-align: left;
  color: #8589FF;
  margin-top: 3vh;
  text-transform:none;
  text-align: justify;
}

.prompt{
  background-color: #AFFE00;
  display: flex;
  position: fixed;
  z-index: 999;
  bottom: 0;
  padding: 2vh;
  text-align: center;
}

.prompt button{
  background-color:#4D50B3;
  padding: 0.5vh 2vh 0.5vh 2vh;
}

.bg{
z-index: -1;
position: fixed;
}

#icon{
  z-index: 4;
  position:absolute;
  color: #AFFE00;
  }
  
  #icon canvas{
    position: absolute;
    left:17vw;
    top:-4vh;
    pointer-events: none;
  }

.stl{
  height: 6vh;
  display: flex;
  background-image:url(../img/stl.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center; 
  box-sizing: border-box;
  z-index: 3;
  color: #AFFE00;
  margin-top: 4vh;
  justify-content: center;
  align-items: center;
  text-shadow: 0px 0px 10px rgba(175, 254, 0, 0.6);
}

.stl a{
  color: #AFFE00;
  text-decoration: none;
  padding: 4%;
}

.day{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  align-items: center;
  top:10%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.dayMain2{
  border-left: 1px #4D50B3 solid;
  border-right: 1px #4D50B3 solid;
  margin: -4px 4px -4px 4px;
  background-color: #0A0C25;
  display: flex;
  flex-direction: column;
}

.day1{
  color: #AFFE00;
  margin-top:-10px;
  text-align: center;
  text-transform: uppercase;
}

.dayNoAll{
  display: flex;
  color: #AFFE00;
  z-index: 1;
  margin-top: 1.2vh;
  justify-content: space-between;
}

.dayNo{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1vw 0 1vw;
}

.dayNo1{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-shadow: 0px 0px 10px rgba(175, 254, 0, 0.6);
}


.first{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.firstGLB{
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
}
#canvas{
  left:-2vw;
  top: 0;
  position:fixed;
  scale:0,
}
.firstH1{
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  text-shadow: -0.2vh -0.2vh 0 rgb(234, 255, 0), 0.2vh 0.2vh 0 rgb(102, 0, 255);
}

.s2{
  display: block;
  position: absolute;
  color: #AFFE00;
  left: 10%;
}

.s3{
  text-align: right;
  position: absolute;
  color: #AFFE00;
  right: 10%;
}

.firstH2{
  font-family: "IBMPlexMono-Regular";
  letter-spacing: 0.1rem;
  color: #AFFE00;
  text-align: center;
  text-transform: uppercase;
  padding:0 4% 10vh 4% ;
  text-shadow: 0px 0px 10px rgba(175, 254, 0, 0.6);
  opacity: 0;
}

.firstTime{
  display: flex;
  width: 100%;
  justify-content: center;

}

.firstTime img{
  width:50%;
}

.star1{
  position: absolute;
  width: 2%;
  top:30%;
  left: 6%;
  z-index: 999;
}

.star2{
  position: absolute;
  width: 6%;
  bottom:20%;
  left: 15%;
  z-index: 999;
}

.star3{
  position: absolute;
  width: 2%;
  bottom:30%;
  right:6%;
  z-index: 999;
}

.star4{
  position: absolute;
  width: 2%;
  top:40%;
  left: 42%;
  z-index: 999;
}

.star5{
  position: absolute;
  width: 4%;
  bottom:10%;
  right: 16%;
  z-index: 999;
}

.two{
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.two h3{
  width: 90vw;
  margin-top: 30vh;
  font-size: 4vw;
}

.twoStep{
  display: flex;
  justify-content: space-evenly;
  padding: 1%;
  width: 80vw;
  margin: 20vh 0 20vh 0;
}

.twoStep01{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
}
.twoStep01-2{
  margin: -1px 1px -1px 1px;
  padding:4%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0A0C25;
  border-left: #4D50B3 1px solid;
  border-right: #4D50B3 1px solid;
}

.button{
  color: #AFFE00;
  background-color: #4D50B3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  margin-top: 4%;
  text-shadow: 0px 0px 0px rgba(175, 254, 0, 0.6);
  border-top:4px solid hsla(0, 0%, 100%, 0.3) ;
  border-left:4px solid hsla(0, 0%, 100%, 0.3) ;
  border-right:4px solid hsla(0, 0%, 0%, 0.3) ;
  border-bottom:4px solid hsla(0, 0%, 0%, 0.3) ;
}

.button a{
  color: #AFFE00;
  text-decoration: none;
}

.twoStep02{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
}

.sms{
  display: flex;
  color: #AFFE00;
  width: 100%;
  margin-top: 4%;
}

.sms1{
  width: 33%;
  text-align:center;
}
.sms1 span{
  display: inline-block;
  vertical-align: middle;
}
.sms1 span img{
  width: 3vw;
  border-top:4px solid #6b6ca5;
  border-left:4px solid #6b6ca5;
  border-right:4px solid #2f306b;
  border-bottom:4px solid #2f306b ;
}

.twoStep03{
  background-color: #05051A;
  width:80vw; 
  display: flex;
  border: #4D50B3 solid 1px;

}

.twoStep03-1{
  width: 50%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.twoStep03-2{
  width: 50%;
  background-image:url(../img/bgPlus.svg);
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.twoStep03-2 img{
  width: 70%;
  border: 1px solid #AFFE00;
  border-radius: 4%;
  
}


.bestTXT{
  display: flex;
  color: #8589FF;
  flex-direction: column;
  padding: 0 4% 10% 4%;
}
.bestTXT h3{
  width: 90%;
  font-size: 4vw;
  text-align:left;
  text-align: justify;
  letter-spacing: -0.5px;
}

.awards{
  display: flex;
  border-top:#4D50B3 1px solid;
  border-bottom:#4D50B3 1px solid;
}

.awardsL{
  width: 20%;
  height: 100vh;
  border-right: 1px solid #4D50B3 ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.awardsLTitle{
  width: 100%;
}
.awardsL-Event{
  color: #AFFE00;
  margin-bottom: 10%;
  background-color: #4D50B3;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0px 0px 10px rgba(175, 254, 0, 0.6);
  padding: 2%;
  margin-top: 2em;
  border-top:4px solid hsla(0, 0%, 100%, 0.3) ;
  border-left:4px solid hsla(0, 0%, 100%, 0.3) ;
  border-right:4px solid hsla(0, 0%, 0%, 0.3) ;
  border-bottom:4px solid hsla(0, 0%, 0%, 0.3) ;
}

.awardsR{
  width: 80%;
  padding: 2vh 0 2vh 0;
}


.awardsR-three{
  display: flex;
  flex-direction: column;
  width: 100%;
}


.awardsR-three-title, .awardsR-three-title2, .awardsR-three-title3{
  text-align: center;
  height: 30vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-shadow: 0px 0px 10px rgba(175, 254, 0, 0.6);
}

.iconCreative, .iconCreative2, .iconCreative3{
  width: 5vw;
  height: auto;
  margin: 1%;
}


.awardsR-three-prize{
  display: flex;
  flex-wrap: wrap;
}
.awardsR-three-prize-ul{
  flex: 12vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0.5px solid #AFFE00;
  border-radius: 0.5em;
  margin: 1%;
  text-align: center;
  padding: 1%;
}

.awardsR-three-prize-ul img{
  width: 100%;
  height: auto;
}

.foot{
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #AFFE00;
  padding: 50vh 0 10vh 0;
  position: relative;
}
.foot img{
  position: absolute;
}
.foot h2{
  margin-top: 16vh;
}
.footStar{
  position: absolute;
  width: 50vw;
}

.loader {
  background: #0A0C25;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index:1100;
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

body #st-1.st-right{
  z-index: 5;
  bottom: -1px;
}

body #st-1 .st-btn{
  border: 0;
}

body #st-1 .st-btn.st-last{
  border: 0;
}

body #st-1 .st-btn.st-first{
  border: 0;
}

body #st-1 .st-btn[data-network='twitter'] {
  background-color: #2f306b;
  border-bottom: #8589FF 0.5px solid;
}

body #st-1 .st-btn[data-network='linkedin'] {
  background-color: #2f306b;
  border-bottom: #8589FF 0.5px  solid;
}

body #st-1 .st-btn[data-network='facebook'] {
  background-color: #2f306b;
  border-bottom: #8589FF 0.5px  solid;
}

body #st-1 .st-btn[data-network='reddit'] {
  background-color: #2f306b;
  border-bottom: #8589FF 0.5px  solid;
}

body #st-1 .st-btn[data-network='sharethis'] {
  background-color: #2f306b;
}

body #st-1 .st-toggle{
  background-color: #4D50B3;
}

body #st-1 .st-btn[data-network='twitter'] > span ,
body #st-1 .st-btn[data-network='linkedin'] > span ,
body #st-1 .st-btn[data-network='facebook'] > span ,
body #st-1 .st-btn[data-network='reddit'] > span ,
body #st-1 .st-btn[data-network='sharethis'] > span
{
  color: #AFFE00;
  font-family:"IBMPlexMono-Regular";
}
